import gql from 'graphql-tag';

const dialogFragment = gql`
  fragment DialogFragment on Dialog {
    _id
    username
    thread_id
    assistant_id
    messages {
      is_user
      message
      updated_at
      created_at
    }
    updated_at
    created_at
  }
`;

export const GQL_GET_DIALOGS = gql`
  query GetDialogs($query: QueryItems) {
    dialogs(query: $query) {
      total
      items {
        ...DialogFragment
      }
    }
  }
  ${dialogFragment}
`;

export const GQL_GET_DIALOG = gql`
  query GetDialog($_id: ID!) {
    dialog(_id: $_id) {
      ...DialogFragment
    }
  }
  ${dialogFragment}
`;

export const GQL_DIALOG_DELETE = gql`
  mutation DeleteDialog($_id: ID!) {
    deleteDialog(_id: $_id)
  }
`;
