import { ApolloClient, DefaultOptions, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

const isProduction = process.env.NODE_ENV === 'production';

let apolloClient: ApolloClient<any> | null = null;

const isBrowser = typeof window !== 'undefined';

const graphQLUrl =
  (isBrowser ? process.env.RAZZLE_API_GRAPHQL_PATH : process.env.RAZZLE_API_GRAPHQL_SSR_PATH) ||
  'http://localhost:3000/graphql';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const httpLink = new HttpLink({
  fetch,
  uri: graphQLUrl,
  fetchOptions: {
    credentials: 'include',
    mode: 'cors',
  },
});

const wsLink = isBrowser
  ? new GraphQLWsLink(
      createClient({
        url: graphQLUrl
          .replace('http://', 'ws://')
          .replace('https://', 'wss://')
          .replace('graphql', 'subscriptions'),
      })
    )
  : undefined;

const createInstance = () => {
  const removeTypenameLink = removeTypenameFromVariables();

  let link = isBrowser
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
          );
        },
        // @ts-ignore - wsLink is not undefined because of the check above
        wsLink,
        httpLink
      )
    : httpLink;

  // Remove __typename from variables
  link = from([removeTypenameLink, link]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    ssrMode: false,
    connectToDevTools: !isProduction,
    ssrForceFetchDelay: 100,
    defaultOptions,
  });
};

export function createApolloClient() {
  if (!apolloClient) {
    // eslint-disable-next-line no-console
    console.log('Apollo client created 🚀');
    apolloClient = createInstance();
  }
  return apolloClient;
}
