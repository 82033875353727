import React from 'react';
import _ from 'lodash';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import styled from '@emotion/styled';
import MuiAppBar from '@mui/material/AppBar';
import { Button, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open: boolean;
}

interface TopBarProps {
  open: boolean;
  toggle: () => void;
  me: any;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
  // @ts-ignore Emotion css prop
})<AppBarProps>(({ theme: { zIndex, transitions }, open }) => ({
  zIndex: zIndex.drawer + 1,
  transition: transitions.create(['width', 'margin'], {
    easing: transitions.easing.sharp,
    duration: transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: transitions.create(['width', 'margin'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  }),
}));

const TopBar = ({ open, toggle, me }: TopBarProps) => {
  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: '28px', // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggle}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          БЛ ГРУПП Нейро
        </Typography>
        <LinkStyle>{_.get(me, 'name', 'Пользователь')}</LinkStyle>
        <Button
          color="inherit"
          onClick={() => {
            window.location.href = '/api/auth/logout';
          }}
        >
          Выход
        </Button>
      </Toolbar>
    </AppBar>
  );
};

const LinkStyle = styled.div`
  margin-right: 20px;
  color: white;
  text-decoration: none;
`;

export default TopBar;
