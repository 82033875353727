import { gql } from '@apollo/client';

const elementFragment = gql`
  fragment ElementFragment on Element {
    _id
    name
    sku
    type {
      _id
      name
      number
    }
    data {
      name
      value
    }
  }
`;

const educationFragment = gql`
  fragment EducationFragment on Education {
    _id
    sku
    request
    response
    weight
    type {
      _id
      name
      number
    }
    data {
      name
      value
    }
  }
`;

const typeFragment = gql`
  fragment TypeFragment on Type {
    _id
    name
    number
  }
`;

export const GQL_GET_ELEMENTS = gql`
  query GetElements($query: QueryItems) {
    elements(query: $query) {
      total
      items {
        ...ElementFragment
      }
    }
  }
  ${elementFragment}
`;

export const GQL_GET_ELEMENT = gql`
  query GetElement($_id: ID!) {
    element(_id: $_id) {
      ...ElementFragment
    }
  }
  ${elementFragment}
`;

export const GQL_UPDATE_ELEMENT = gql`
  mutation UpdateElement($element: InputElement!) {
    updateElement(element: $element) {
      ...ElementFragment
    }
  }
  ${elementFragment}
`;

export const GQL_DELETE_ELEMENT = gql`
  mutation DeleteElement($_id: ID!) {
    deleteElement(_id: $_id)
  }
`;

export const GQL_GET_EDUCATIONS = gql`
  query GetEducations($query: QueryItems) {
    educations(query: $query) {
      total
      items {
        ...EducationFragment
      }
    }
  }
  ${educationFragment}
`;

export const GQL_GET_EDUCATION = gql`
  query GetEducation($_id: ID!) {
    education(_id: $_id) {
      ...EducationFragment
    }
  }
  ${educationFragment}
`;

export const GQL_UPDATE_EDUCATION = gql`
  mutation UpdateEducation($education: InputEducation!) {
    updateEducation(education: $education) {
      ...EducationFragment
    }
  }
  ${educationFragment}
`;

export const GQL_DELETE_EDUCATION = gql`
  mutation DeleteEducation($_id: ID!) {
    deleteEducation(_id: $_id)
  }
`;

export const GQL_GET_TYPES = gql`
  query GetTypes($query: QueryItems) {
    types(query: $query) {
      total
      items {
        ...TypeFragment
      }
    }
  }
  ${typeFragment}
`;

export const GQL_GET_TYPE = gql`
  query GetType($_id: ID!) {
    type(_id: $_id) {
      ...TypeFragment
    }
  }
  ${typeFragment}
`;

export const GQL_UPDATE_TYPE = gql`
  mutation UpdateType($_id: ID, $name: String!) {
    updateType(_id: $_id, name: $name) {
      ...TypeFragment
    }
  }
  ${typeFragment}
`;

export const GQL_DELETE_TYPE = gql`
  mutation DeleteType($_id: ID!) {
    deleteType(_id: $_id)
  }
`;

export const GQL_SEND_ELEMENTS = gql`
  mutation SendElementNeural($type_id: ID!) {
    sendElementNeural(type_id: $type_id)
  }
`;

export const GQL_SEND_EDUCATION = gql`
  mutation SendEducationNeural($type_id: ID!) {
    sendEducationNeural(type_id: $type_id)
  }
`;
