import React from 'react';
import { Global, css } from '@emotion/react';

export const GlobalStyle = () => (
  <Global
    styles={css`
      body {
        margin: 0;
        padding: 0;
        font-family: 'Roboto', sans-serif;
      }
      *,
      *::before,
      *::after {
        box-sizing: border-box;
      }
      .w-100 {
        width: 100%;
      }
    `}
  />
);
