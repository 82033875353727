import loadable, { LoadableComponent } from '@loadable/component';
import React from 'react';
import { useQuery } from '@apollo/client';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';

import { GQL_GET_ME } from './graphql/queries';
import MasterAdmin from './layout/MasterAdmin';

const fallback = () => (
  <div className="loader">
    <div className="bar" />
  </div>
);

// const Dashboard = loadable(() => import('./screens/Dashboard'), {
//   ssr: false,
//   fallback: fallback(),
// });
const Users = loadable(() => import('./screens/users/Users'), {
  ssr: false,
  fallback: fallback(),
});
const Register = loadable(() => import('./screens/Register'), {
  ssr: false,
  fallback: fallback(),
});
const ErrorScreen = loadable(() => import('./screens/ErrorScreen'), {
  ssr: false,
  fallback: fallback(),
});
const UserUpdate = loadable(() => import('./screens/users/UserUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const Rbac = loadable(() => import('./screens/rbac'), {
  ssr: false,
  fallback: fallback(),
});
const AssignmentUpdate = loadable(() => import('./screens/rbac/assignment/AssignmentUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const RoleUpdate = loadable(() => import('./screens/rbac/role/RoleUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const RuleUpdate = loadable(() => import('./screens/rbac/rule/RuleUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const SettingUpdate = loadable(() => import('./screens/setting/SettingUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const Settings = loadable(() => import('./screens/setting/Settings'), {
  ssr: false,
  fallback: fallback(),
});
const DialogView = loadable(() => import('./screens/dialogs/DialogView'), {
  ssr: false,
  fallback: fallback(),
});
const Dialogs = loadable(() => import('./screens/dialogs/Dialogs'), {
  ssr: false,
  fallback: fallback(),
});
const Neural = loadable(() => import('./screens/neural'), {
  ssr: false,
  fallback: fallback(),
});
const TypeUpdate = loadable(() => import('./screens/neural/type/TypeUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const ElementUpdate = loadable(() => import('./screens/neural/element/ElementUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const EducationUpdate = loadable(() => import('./screens/neural/education/EducationUpdate'), {
  ssr: false,
  fallback: fallback(),
});

const RouteNavigator = () => {
  const { data } = useQuery(GQL_GET_ME);

  const me = _.get(data, 'me', {});

  const AdminMaster: any = (
    page: LoadableComponent<any>,
    props: {
      container: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
      me: object | null;
    }
  ) => MasterAdmin(page, props);

  return (
    <Switch>
      <Route path="/" component={AdminMaster(Neural, { container: false, me })} exact />
      <Route path="/register" component={Register} exact />
      <Route path="/users" component={AdminMaster(Users, { container: false, me })} exact />
      <Route
        path="/users/update/:_id?"
        component={AdminMaster(UserUpdate, { container: 'md', me })}
        exact
      />
      <Route path="/settings" component={AdminMaster(Settings, { container: false, me })} exact />
      <Route
        path="/settings/update/:_id?"
        component={AdminMaster(SettingUpdate, { container: 'md', me })}
        exact
      />
      <Route path="/dialogs" component={AdminMaster(Dialogs, { container: false, me })} exact />
      <Route
        path="/dialogs/:_id?"
        component={AdminMaster(DialogView, { container: 'md', me })}
        exact
      />
      <Route path="/rbac/:section?" component={AdminMaster(Rbac, { container: false, me })} exact />
      <Route
        path="/rbac/assignments/update/:_id?"
        component={AdminMaster(AssignmentUpdate, { container: 'md', me })}
        exact
      />
      <Route
        path="/rbac/roles/update/:_id?"
        component={AdminMaster(RoleUpdate, { container: 'md', me })}
        exact
      />
      <Route
        path="/rbac/rules/update/:_id?"
        component={AdminMaster(RuleUpdate, { container: 'md', me })}
        exact
      />
      <Route
        path="/neural/:section?"
        component={AdminMaster(Neural, { container: false, me })}
        exact
      />
      <Route
        path="/neural/types/update/:_id?"
        component={AdminMaster(TypeUpdate, { container: 'md', me })}
        exact
      />
      <Route
        path="/neural/elements/update/:_id?"
        component={AdminMaster(ElementUpdate, { container: 'md', me })}
        exact
      />
      <Route
        path="/neural/educations/update/:_id?"
        component={AdminMaster(EducationUpdate, { container: 'md', me })}
        exact
      />
      <Route path="/*" component={ErrorScreen} exact />
    </Switch>
  );
};

export default RouteNavigator;
