import { gql } from '@apollo/client';

const settingFragment = gql`
  fragment SettingFragment on Setting {
    _id
    name
    code
    type
    value
    status
  }
`;

export const GQL_GET_SETTINGS = gql`
  query GetSettings($query: QueryItems) {
    settings(query: $query) {
      total
      items {
        ...SettingFragment
      }
    }
  }
  ${settingFragment}
`;

export const GQL_GET_SETTING = gql`
  query GetSetting($_id: ID!) {
    setting(_id: $_id) {
      ...SettingFragment
    }
  }
  ${settingFragment}
`;

export const GQL_SETTING_UPDATE = gql`
  mutation UpdateSetting($setting: InputSetting) {
    updateSetting(setting: $setting) {
      ...SettingFragment
    }
  }
  ${settingFragment}
`;

export const GQL_SETTING_DELETE = gql`
  mutation DeleteSetting($_id: ID!) {
    deleteSetting(_id: $_id)
  }
`;
