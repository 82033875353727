import React, { useEffect, useState } from "react";
import styled from '@emotion/styled';
import MuiDrawer from '@mui/material/Drawer';
import { IconButton, List, Toolbar } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import { Hub, Message, Security, SettingsApplications } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

import { can } from '../helpers/access';

const drawerWidth: number = 240;

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  // @ts-ignore Emotion css prop
  ({ theme: { transitions, spacing, breakpoints }, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: transitions.create('width', {
          easing: transitions.easing.sharp,
          duration: transitions.duration.leavingScreen,
        }),
        width: '60px',
      }),
    },
  })
);

interface SideMenuProps {
  open: boolean;
  toggle: () => void;
  me: any;
}

const SideMenu = ({ open, toggle, me }: SideMenuProps) => {
  const history = useHistory();
  const [active, setActive] = useState<string>('neural');
  const to = (path: string) => () => {
    history.push(`/${path}`);
    setActive(path);
  };

  useEffect(() => {
    const path = history.location.pathname.split('/')[1];
    setActive(path);
  }, []);

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggle}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <List component="nav">
        {/* <ListItemButton className={active ? 'active' : ''} onClick={() => to('/')}> */}
        {/*  <ListItemIcon> */}
        {/*    <DashboardIcon /> */}
        {/*  </ListItemIcon> */}
        {/*  <ListItemText primary="Главная" /> */}
        {/* </ListItemButton> */}
        {can({ me, rule: 'backend.neural.elements.list' }) && (
          <ListItemButton selected={active === 'neural'} onClick={to('neural')}>
            <ListItemIcon>
              <Hub />
            </ListItemIcon>
            <ListItemText primary="Нейро БД" />
          </ListItemButton>
        )}
        {can({ me, rule: 'backend.dialogs.list' }) && (
          <ListItemButton selected={active === 'dialogs'} onClick={to('dialogs')}>
            <ListItemIcon>
              <Message />
            </ListItemIcon>
            <ListItemText primary="Диалоги" />
          </ListItemButton>
        )}
        {can({ me, rule: 'backend.users.list' }) && (
          <ListItemButton selected={active === 'users'} onClick={to('users')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            §
            <ListItemText primary="Пользователи" />
          </ListItemButton>
        )}
        {can({ me, rule: 'backend.settings.list' }) && (
          <ListItemButton selected={active === 'settings'} onClick={to('settings')}>
            <ListItemIcon>
              <SettingsApplications />
            </ListItemIcon>
            <ListItemText primary="Настройки" />
          </ListItemButton>
        )}
        {can({ me, rule: 'backend.rbac.roles.list' }) && (
          <ListItemButton selected={active === 'rbac'} onClick={to('rbac')}>
            <ListItemIcon>
              <Security />
            </ListItemIcon>
            <ListItemText primary="RBAC" />
          </ListItemButton>
        )}
      </List>
    </Drawer>
  );
};

export default SideMenu;
