/* eslint-disable import/no-import-module-exports */
import { loadableReady } from '@loadable/component';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './screens/App';
import { createApolloClient } from './graphql/client';

const AppComponent = () => {
  const client = createApolloClient();
  return (
    <BrowserRouter>
      <App client={client} />
    </BrowserRouter>
  );
};
loadableReady().then(() => {
  const renderMethod = module.hot ? render : hydrate;
  renderMethod(<AppComponent />, document.getElementById('root'));
});

if (module.hot) {
  module.hot.accept();
}
