import _ from 'lodash';

interface UserAccess {
  me: {
    assignment: {
      roles: {
        rules: {
          rule: string;
        }[];
      }[];
    };
  } | null;
  rule: string;
}

const _checkAccess = (rules: any[], name: string): boolean =>
  !_.isEmpty(rules) && rules.some((rule) => rule && rule.rule === name);

/**
 * RBAC checker
 * @description Check if user has access to the action or view
 * look to API /src/utilities/rbac.js
 * @param {Object|null} me
 * @param {String} rule - rule name like 'backend.users.update'
 * @returns {boolean} - true if user has access to the rule
 */
export const can = ({ me, rule }: UserAccess): boolean => {
  const ruleParts = rule.split('.');
  const assignment = _.get(me, 'assignment', {});
  if (_.isEmpty(assignment)) {
    return false;
  }
  const roles = _.get(assignment, 'roles', []);
  // accumulate all rules from user roles
  const userRules = roles.reduce((acc, role) => {
    const rules = _.get(role, 'rules', []);
    return acc.concat(rules);
  }, []);

  let rulePath = '';
  // Here we try to find one of the rule variant in user roles
  // example, we have ruleName 'backend.users.update'
  // Break it to parts by ruleParts upper, then try here
  // 1) try 'backend' if not ->
  // 2) try 'backend.users' if not ->
  // 3) try 'backend.users.update' if not than not allowed
  return ruleParts.some((ruleName) => {
    rulePath = rulePath ? `${rulePath}.${ruleName}` : ruleName;
    return _checkAccess(userRules, rulePath);
  });
};
