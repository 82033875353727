import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  ThemeProvider,
  Toolbar,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../ErrorBoundary';
import Login from '../screens/Login';
import TopBar from './_bar';
import SideMenu from './_side';

const defaultTheme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
});

export default (
  Component: any,
  props: {
    container: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    me: object | null;
  }
): any => {
  const { container, me } = props;

  const [open, setOpen] = useState(true);

  useEffect(() => {
    const session = localStorage.getItem('sidebar');

    if (session === 'false') {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, []);

  const toggle = () => {
    localStorage.setItem('sidebar', open ? 'false' : 'true');
    setOpen(!open);
  };

  return (masterProps: any) => {
    if (!_.get(me, '_id')) {
      return <Login />;
    }
    return (
      <ErrorBoundary {...masterProps}>
        <ThemeProvider theme={defaultTheme}>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <TopBar {...{ open, toggle, me }} />
            <SideMenu {...{ open, toggle, me }} />
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
              }}
            >
              <Toolbar />
              <Container maxWidth={container} sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Component {...masterProps} />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
        </ThemeProvider>
      </ErrorBoundary>
    );
  };
};
