import React from 'react';
import { ErrorBoundary as ReactError } from 'react-error-boundary';

const ErrorBoundary = ({ children }: any) => {
  const refreshPage = () => {
    window.location.reload();
  };

  const renderError = ({ error, resetErrorBoundary }: any) => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        zIndex: 1501,
        top: 20,
        left: 0,
      }}
    >
      <button
        type="button"
        onClick={resetErrorBoundary}
        style={{
          border: 0,
          borderRadius: 4,
          fontSize: 14,
          textDecoration: 'none',
          background: '#d5f2fe',
          color: '#000',
          padding: '10px 20px',
          maxWidth: 340,
          textAlign: 'left',
        }}
      >
        <b
          style={{
            color: '#fa0000',
            display: 'block',
            fontSize: 15,
            marginBottom: 10,
          }}
        >
          Информация:
        </b>
        {error.name === 'ChunkLoadError'
          ? 'Перезагрузите приложение, мы обновили его'
          : 'Что-то пошло не так!'}
      </button>
    </div>
  );

  return (
    <ReactError FallbackComponent={renderError} onReset={refreshPage}>
      {children}
    </ReactError>
  );
};

export default ErrorBoundary;
