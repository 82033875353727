import React, { ReactElement, useEffect } from 'react';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import Cookies from 'universal-cookie';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import _ from 'lodash';

import RouteNavigator from '../RouteNavigator';
import { GlobalStyle } from '../styles/global';

const App = ({ client }: { client: ApolloClient<any> }): ReactElement => {
  useEffect(() => {
    const jwtCheck = setInterval(() => {
      const cookies = new Cookies();
      const token = cookies.get('token');
      if (!_.isEmpty(token)) {
        const decoded = jwt.decode(token);
        if (decoded && moment().isBefore(moment.unix(+decoded))) {
          return false;
        }
      }
      return false;
    }, 10000);
    return () => {
      clearInterval(jwtCheck);
    };
  }, []);
  return (
    <ApolloProvider client={client}>
      <SnackbarProvider maxSnack={5}>
        <GlobalStyle />
        <RouteNavigator />
      </SnackbarProvider>
    </ApolloProvider>
  );
};

export default App;
