import { gql } from '@apollo/client';

const assignmentFragment = gql`
  fragment AssignmentFragment on Assignment {
    _id
    owner_id
    owner {
      _id
      name
    }
    roles {
      _id
      name
      slug
    }
    status
  }
`;

const roleFragment = gql`
  fragment RoleFragment on Role {
    _id
    name
    slug
    rules {
      _id
      name
    }
    status
  }
`;

const ruleFragment = gql`
  fragment RuleFragment on Rule {
    _id
    name
    rule
  }
`;

export const GQL_GET_ASSIGNMENTS = gql`
  query GetAssignments {
    assignments {
      total
      items {
        ...AssignmentFragment
      }
    }
  }
  ${assignmentFragment}
`;

export const GQL_GET_ASSIGNMENT = gql`
  query GetAssignment($_id: ID!) {
    assignment(_id: $_id) {
      ...AssignmentFragment
    }
  }
  ${assignmentFragment}
`;

export const GQL_ASSIGNMENT_UPDATE = gql`
  mutation UpdateAssignment($_id: ID, $owner_id: ID!, $roles: [ID!], $status: String!) {
    updateAssignment(_id: $_id, owner_id: $owner_id, roles: $roles, status: $status) {
      ...AssignmentFragment
    }
  }
  ${assignmentFragment}
`;

export const GQL_ASSIGNMENT_DELETE = gql`
  mutation DeleteAssignment($_id: ID!) {
    deleteAssignment(_id: $_id)
  }
`;

export const GQL_GET_ROLES = gql`
  query GetRoles {
    roles {
      total
      items {
        ...RoleFragment
      }
    }
  }
  ${roleFragment}
`;

export const GQL_GET_ROLE = gql`
  query GetRole($_id: ID!) {
    role(_id: $_id) {
      ...RoleFragment
    }
  }
  ${roleFragment}
`;

export const GQL_ROLE_UPDATE = gql`
  mutation UpdateRole($_id: ID, $name: String!, $slug: String!, $rules: [ID]!, $status: String!) {
    updateRole(_id: $_id, name: $name, slug: $slug, rules: $rules, status: $status) {
      ...RoleFragment
    }
  }
  ${roleFragment}
`;

export const GQL_ROLE_DELETE = gql`
  mutation DeleteRole($_id: ID!) {
    deleteRole(_id: $_id)
  }
`;

export const GQL_GET_RULES = gql`
  query GetRules {
    rules {
      total
      items {
        ...RuleFragment
      }
    }
  }
  ${ruleFragment}
`;

export const GQL_GET_RULE = gql`
  query GetRule($_id: ID!) {
    rule(_id: $_id) {
      ...RuleFragment
    }
  }
  ${ruleFragment}
`;

export const GQL_RULE_UPDATE = gql`
  mutation UpdateRule($_id: ID, $name: String!, $rule: String!) {
    updateRule(_id: $_id, name: $name, rule: $rule) {
      ...RuleFragment
    }
  }
  ${ruleFragment}
`;

export const GQL_RULE_DELETE = gql`
  mutation DeleteRule($_id: ID!) {
    deleteRule(_id: $_id)
  }
`;
